import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'calculateDuration'
})
export class CalculateDurationPipe implements PipeTransform {

    transform(dateStart: string, dateEnd: string) {
        if (dateStart == null && dateEnd == null) {
            return '00:00';
        } else {
            let start = dateStart != null ? parseInt(moment(dateStart).format('X')) : parseInt(moment().format('X'));
            let end = dateEnd != null ? parseInt(moment(dateEnd).format('X')) : parseInt(moment().format('X'));
            let dif = end - start;
            return moment.unix(dif).format('HH:mm');
        }
    }

}
